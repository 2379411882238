import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import PropTypes from 'prop-types';
import Textarea from 'components/base-components/Textarea';
import { useTranslation } from 'react-i18next';
import Input from 'components/base-components/Input';
import { Table } from 'components/base-components/Table';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Button from 'components/base-components/Button';
import { GASTRONOMY } from 'utils/organisation-industries';

const LineItems = ({ setRemovedItems }) => {
  const { t } = useTranslation();
  const { values } = useFormState();

  return (
    <div>
      <FieldArray name="items">
        {({ fields }) => (
          <Table className="invoice__item--table">
            {fields.map((name, index) => (
              <>
                <div className="invoice__item--wrapper">
                  <Field name={`${name}.id`}>
                    {({ input, meta }) => <input type="hidden" {...input} />}
                  </Field>
                  <div className="invoice__item">
                    {t('invoice.line_item')} {index + 1}
                  </div>
                  <IconButton
                    icon={<Icon name="delete" size="small" />}
                    size="small"
                    color="danger"
                    onClick={() => {
                      fields.remove(index);
                      setRemovedItems(prevRemovedItems => [
                        ...prevRemovedItems,
                        fields.value[index],
                      ]);
                    }}
                  />
                </div>
                <div className="invoice__item-details">
                  <Field name={`${name}.name`}>
                    {({ input, meta }) => (
                      <Input
                        className="invoice__line-input"
                        label={t('invoice.line_items.service')}
                        size="tiny"
                        placeholder={t('invoice.enter_service')}
                        required={true}
                        error={meta.error || meta.submitError}
                        touched={!meta.dirtySinceLastSubmit}
                        isClearable={false}
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name={`${name}.price`}>
                    {({ input, meta }) => (
                      <Input
                        className="invoice__line-input"
                        label={t('invoice.line_items.price')}
                        type="number"
                        size="tiny"
                        required={true}
                        placeholder={t('invoice.enter_chf')}
                        error={meta?.submitError}
                        touched={meta.touched}
                        isClearable={false}
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name={`${name}.quantity`}>
                    {({ input, meta }) => (
                      <Input
                        className="invoice__line-input"
                        label={t('invoice.line_items.qty')}
                        type="number"
                        size="tiny"
                        placeholder={t('invoice.enter_quantity')}
                        error={meta?.submitError}
                        touched={meta.touched}
                        isClearable={false}
                        required={true}
                        {...input}
                        onChange={value => input.onChange(parseInt(value, 10))}
                      />
                    )}
                  </Field>
                </div>
                <div className="margin-top-20">
                  <Field name={`${name}.description`}>
                    {({ input, meta }) => (
                      <Textarea
                        placeholder={t('invoice.enter_description')}
                        label={t('invoice.line_items.des')}
                        error={meta.submitError}
                        touched={!meta.dirtySinceLastSubmit}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className="invoice__form-cost-center">
                  <Field name={`${name}.cost_center`}>
                    {({ input, meta }) => (
                      <Input
                        className="invoice__line-input"
                        label={t('invoice.line_items.cost_center')}
                        size="tiny"
                        placeholder={t('invoice.line_items.placeholder.cost_center')}
                        error={meta.submitError}
                        touched={meta.touched}
                        isClearable={false}
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name={`${name}.cost_element`}>
                    {({ input, meta }) => (
                      <Input
                        className="invoice__line-input"
                        label={t('invoice.line_items.cost_element')}
                        size="tiny"
                        placeholder={t('invoice.line_items.placeholder.cost_element')}
                        error={meta.submitError}
                        touched={meta.touched}
                        isClearable={false}
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name={`${name}.vat_rate`}>
                    {({ input, meta }) => (
                      <Input
                        className="invoice__line-input"
                        label={t('invoice.line_items.vat')}
                        type="number"
                        size="tiny"
                        placeholder={t('invoice.enter_vat')}
                        error={meta.submitError}
                        touched={meta.touched}
                        isClearable={false}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
              </>
            ))}
            <div className="invoice__form-button">
              <Button
                label={t('invoice.line_items.add_line_item')}
                size="small"
                disabled={values?.department !== GASTRONOMY && !values.type}
                onClick={() => {
                  fields.push({});
                }}
                type="default"
              />
            </div>
          </Table>
        )}
      </FieldArray>
    </div>
  );
};

LineItems.propTypes = {
  setRemovedItems: PropTypes.func.isRequired,
};
export default LineItems;
