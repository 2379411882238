import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import { t } from 'i18next';
import { dateMonthYear } from 'utils/date-time-formatter';
import PropTypes from 'prop-types';
import React from 'react';
import EmphasisTag from 'components/base-components/EmphasisTag';
import { Link } from 'react-router-dom';
import useNavigationLinks from 'hooks/useNavigationLinks';
import InvoiceJobTag from 'components/invoices/shared/invoice-job-tag';

const InvoiceJobsTableRow = ({ invoiceJob }) => {
  const {
    createdAt,
    id,
    department,
    status,
    totalInvoiceCount,
    draftInvoiceCount,
    openInvoiceCount,
    paidInvoiceCount,
    cancelledInvoiceCount,
    type,
  } = invoiceJob;

  const navigationLinks = useNavigationLinks();

  return (
    <>
      <TableRow className="invoice-list__table-row">
        <TableData>{dateMonthYear(createdAt)}</TableData>
        <TableData align="center">{id || t('common.not_applicable')}</TableData>
        <TableData align="center">
          <EmphasisTag type="normal" size="tiny" text={t(`organisation.industry.${department}`)} />
        </TableData>
        <TableData>{t(`invoice.invoice_job.types.${type}`)}</TableData>
        <TableData>
          <InvoiceJobTag status={status} />
        </TableData>
        <TableData>{totalInvoiceCount || '0'}</TableData>
        <TableData>{draftInvoiceCount || '0'}</TableData>
        <TableData>{openInvoiceCount || '0'}</TableData>
        <TableData>{paidInvoiceCount || '0'}</TableData>
        <TableData>{cancelledInvoiceCount || '0'}</TableData>
        <TableData>0</TableData>
        <TableData align="center">
          <Link to={navigationLinks.invoiceListPage(invoiceJob.id)}>
            <IconButton icon={<Icon name="showAlt" />} />
          </Link>
        </TableData>
      </TableRow>
    </>
  );
};

export const InvoiceJobTableHeader = () => (
  <TableHead>
    <TableRow>
      <TableHeader>{t('invoice.creation_date')}</TableHeader>
      <TableHeader align="center">{t('invoice.identifier')}</TableHeader>
      <TableHeader align="center">{t('invoice.department')}</TableHeader>
      <TableHeader>{t('invoice.job_type')}</TableHeader>
      <TableHeader>{t('invoice.status')}</TableHeader>
      <TableHeader>{t('sidenav.invoices')}</TableHeader>
      <TableHeader>{t('invoice.tag_status.draft')}</TableHeader>
      <TableHeader>{t('invoice.tag_status.open')}</TableHeader>
      <TableHeader>{t('invoice.tag_status.paid')}</TableHeader>
      <TableHeader>{t('invoice.tag_status.cancelled')}</TableHeader>
      <TableHeader>{t('invoice.tag_status.failed')}</TableHeader>
      <TableHeader align="center">{t('shared.actions')}</TableHeader>
    </TableRow>
  </TableHead>
);

const InvoiceJobsTable = ({ invoiceJobs, isFetching }) => {
  return (
    <>
      {!isFetching && invoiceJobs.length === 0 ? (
        <EmptyContentPlaceholder iconName="invoice" text={t('invoice.no_invoice')} />
      ) : (
        <>
          <Table>
            <InvoiceJobTableHeader />
            <TableBody>
              {isFetching ? (
                <TableLoaderPlaceholder numberOfRows={10} numberOfColumns={12} />
              ) : (
                invoiceJobs.map((invoiceJob, i) => (
                  <InvoiceJobsTableRow key={i} invoiceJob={invoiceJob} />
                ))
              )}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
};

InvoiceJobsTableRow.propTypes = {
  invoiceJob: PropTypes.shape().isRequired,
};

InvoiceJobsTable.defaultProps = {
  isFetching: false,
};

InvoiceJobsTable.propTypes = {
  invoiceJobs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isFetching: PropTypes.bool,
};

export default InvoiceJobsTable;
