import Button from 'components/base-components/Button';
import PropTypes from 'prop-types';
import CheckBox from 'components/base-components/CheckBox';
import DatePicker from 'components/base-components/DatePicker';
import i18n from 'i18next';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import ProducerItemsForm from 'components/invoices/shared/producer-items-form.component';

const CorrectInvoiceForm = ({ onCorrect, setIsSidepanelOpen, invoice }) => {
  const [enableInvoiceDatePicker, setEnableInvoiceDatePicker] = useState(null);

  const onSubmit = values => onCorrect(values, invoice.id);
  const generalData = invoice?.seasonData || {};

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Field name="invoiceDate" initialValue={moment().format('YYYY-MM-DD')}>
            {({ input, meta }) => (
              <DatePicker
                focused={enableInvoiceDatePicker}
                date={input.value}
                onChange={e => input.onChange(e)}
                touched={!meta.dirtySinceLastSubmit}
                error={meta.submitError}
                onFocusChange={() => setEnableInvoiceDatePicker(!enableInvoiceDatePicker)}
                displayFormat="LL"
                label={t('tasks.create_form.due_date_placeholder')}
                navNext="arrowForwardAlt"
                navPrev="arrowBackAlt"
                size="tiny"
                enablePastDates={true}
                disableFutureDates={false}
                futureYearsCount={0}
                locale={i18n.language}
              />
            )}
          </Field>
          <div className="invoice__correct--date-footer">{t('invoice.correct.date_footer')}</div>
          <Field name="changeGeneralDataCheckbox" type="checkbox">
            {({ input }) => (
              <CheckBox
                className="invoice__correct--content"
                isChecked={input.checked}
                size="tiny"
                label={t('invoice.correct.change_general_data')}
                onChange={e => input.onChange(e.target.checked)}
              />
            )}
          </Field>
          {values.changeGeneralDataCheckbox && <ProducerItemsForm generalData={generalData} />}
          <div className="invoice__correct--button">
            <Button
              label={t('invoice.correct.title')}
              type="success"
              size="small"
              submitType="submit"
              disabled={submitting}
            />
            <Button
              label={t('shared.action.cancel')}
              type="default"
              size="small"
              onClick={() => setIsSidepanelOpen(false)}
            />
          </div>
        </form>
      )}
    />
  );
};

CorrectInvoiceForm.defaultProps = {
  onCorrect: () => {},
  setIsSidepanelOpen: () => {},
  organisationId: null,
};

CorrectInvoiceForm.propTypes = {
  onCorrect: PropTypes.func,
  setIsSidepanelOpen: PropTypes.func,
  organisationId: PropTypes.node,
  invoice: PropTypes.shape().isRequired,
};

export default CorrectInvoiceForm;
