import EmphasisTag from 'components/base-components/EmphasisTag';
import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

const InvoiceStatusTag = ({ status }) => {
  const types = {
    open: 'warning',
    overdue: 'danger',
    paid: 'success',
    pending: 'warning',
    draft: 'normal',
    cancelled: 'disabled',
    closed: 'accent',
    scheduled: 'accent',
    completed: 'success',
    scheduled_for_release: 'accent',
  };

  if (!types[status]) return t('shared.not_available');

  const text = t(`invoice.tag_status.${status}`);

  const type = types[status];

  return <EmphasisTag type={type} size="tiny" text={text} />;
};

InvoiceStatusTag.propTypes = {
  status: PropTypes.string.isRequired,
};

const InvoiceTag = ({ invoice }) => {
  let status = invoice.status;
  if (invoice.correctedInvoiceId) {
    status = 'closed';
  }

  return <InvoiceStatusTag status={status} />;
};

InvoiceTag.propTypes = {
  invoice: PropTypes.shape().isRequired,
};

export default InvoiceTag;
