import classNames from 'classnames';
import Input from 'components/base-components/Input';
import SelectBox from 'components/base-components/SelectBox';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

const ProducerItemsForm = ({ generalData, isLineItemForm }) => {
  const selectBoxOptions = [
    {
      label: t('shared.action.true'),
      value: true,
    },
    {
      label: t('shared.action.false'),
      value: false,
    },
  ];
  return (
    <div className={classNames({ 'margin-bottom-20': isLineItemForm })}>
      <div className={classNames({ 'col-6 col-bleed ': isLineItemForm })}>
        <Field name="zone" initialValue={generalData?.zoneNr}>
          {({ input }) => (
            <Input
              className="invoice__correct--content"
              size="tiny"
              label={t('invoice.correct.zone')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              {...input}
            />
          )}
        </Field>
      </div>
      <div
        className={classNames({
          'col-6 col-bleed invoice__form-input--right': isLineItemForm,
        })}
      >
        <Field name="summerOperation" initialValue={generalData?.summerPastureHoldings}>
          {({ input }) => (
            <SelectBox
              className="invoice__correct--content"
              size="tiny"
              width="large"
              label={t('invoice.correct.summer_operation')}
              isClearable={false}
              options={selectBoxOptions}
              value={selectBoxOptions.find(option => option.value === input.value)}
              selectedValue={input.label}
              onChange={e => input.onChange(e.value)}
            />
          )}
        </Field>
      </div>
      <div className={classNames({ 'col-6 col-bleed ': isLineItemForm })}>
        <Field name="usableArea" initialValue={generalData?.agriculturalUsableAreaTotalInM2}>
          {({ input }) => (
            <Input
              size="tiny"
              className="invoice__correct--content"
              label={t('invoice.correct.usable_area')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              {...input}
            />
          )}
        </Field>
      </div>
      <div
        className={classNames({
          'col-6 col-bleed invoice__form-input--right': isLineItemForm,
        })}
      >
        <Field name="greenArea" initialValue={generalData?.grasslandInM2}>
          {({ input }) => (
            <Input
              size="tiny"
              className="invoice__correct--content"
              label={t('invoice.correct.green_area')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              {...input}
            />
          )}
        </Field>
      </div>
      <Field name="livestockInDgve" initialValue={generalData?.livestockInDgve}>
        {({ input }) => (
          <Input
            size="tiny"
            className="invoice__correct--content"
            label={t('invoice.correct.population')}
            placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
            {...input}
          />
        )}
      </Field>
      <div className={classNames({ 'col-6 col-bleed ': isLineItemForm })}>
        <Field name="openLand" initialValue={generalData?.openArableLandInM2}>
          {({ input }) => (
            <Input
              size="tiny"
              className="invoice__correct--content"
              label={t('invoice.correct.open_land')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              {...input}
            />
          )}
        </Field>
      </div>
      <div
        className={classNames({
          'col-6 col-bleed invoice__form-input--right': isLineItemForm,
        })}
      >
        <Field name="specialCrops" initialValue={generalData?.specialAndPermanentCropsInM2}>
          {({ input }) => (
            <Input
              size="tiny"
              className="invoice__correct--content"
              label={t('invoice.correct.special_crops')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              {...input}
            />
          )}
        </Field>
      </div>
      <div className={classNames({ 'col-6 col-bleed ': isLineItemForm })}>
        <Field
          name="protectedCultivation"
          initialValue={generalData?.cropsInProtectedCultivationInM2}
        >
          {({ input }) => (
            <Input
              size="tiny"
              label={t('invoice.correct.protected')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              className="invoice__correct--content"
              {...input}
            />
          )}
        </Field>
      </div>
      <div
        className={classNames({
          'col-6 col-bleed invoice__form-input--right': isLineItemForm,
        })}
      >
        <Field name="demeter" initialValue={!!generalData?.demeter}>
          {({ input, meta }) => (
            <SelectBox
              size="tiny"
              className="invoice__correct--content"
              width="large"
              label={t('invoice.correct.demeter')}
              isClearable={false}
              options={selectBoxOptions}
              value={selectBoxOptions.find(option => option.value === input.value)}
              selectedValue={input.label}
              onChange={e => input.onChange(e.value)}
            />
          )}
        </Field>
      </div>
    </div>
  );
};

ProducerItemsForm.defaultProps = {
  generalData: {},
  isLineItemForm: false,
};

ProducerItemsForm.propTypes = {
  generalData: PropTypes.shape(),
  isLineItemForm: PropTypes.bool,
};

export default ProducerItemsForm;
