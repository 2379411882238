import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import AmountComponent from 'components/shared-components/Amount';
import numberFormatter from 'utils/number-formatter';

const InvoiceSummary = ({ invoice }) => {
  const { totalAmountExclVat, totalAmountInclVat, totalVatAmountsByVatRates } = invoice;

  return (
    <>
      <div className="invoice__summary">
        <div className="invoice__summary-item">
          <span>{t('invoice.total_net_amount')}</span>
          <span>
            {t('invoice.chf')} {totalAmountExclVat}
          </span>
        </div>
        {totalVatAmountsByVatRates?.map((totalVatAmount, index) => {
          const isVatAvailable = totalVatAmount.percentage && totalVatAmount.percentage !== '0.0';

          return (
            isVatAvailable && (
              <div key={index} className="invoice__summary-item">
                <span>
                  {t('invoice.total_vat_with_percentage', {
                    percentage: totalVatAmount.percentage,
                  })}
                </span>
                <AmountComponent value={numberFormatter(totalVatAmount.value)} />
              </div>
            )
          );
        })}
      </div>
      <div className="invoice__summary invoice__summary--border-right">
        <div className="invoice__summary-item invoice__summary-item--bold">
          <span>{t('invoice.total_amount_chf')}</span>
          <span>
            {t('invoice.chf')} {totalAmountInclVat}
          </span>
        </div>
        <div className="invoice__summary-item">
          <span>{t('invoice.paid_chf')}</span>
          <AmountComponent value={invoice?.totalReceivedAmount} />
        </div>
      </div>
      <div className="invoice__summary">
        <div className="invoice__summary-item invoice__summary-item--bold">
          <span>{t('invoice.remaining_due')}</span>
          <AmountComponent value={invoice?.totalRemainingDue} />
        </div>
      </div>
    </>
  );
};

InvoiceSummary.propTypes = {
  invoice: PropTypes.shape().isRequired,
};

export default InvoiceSummary;
