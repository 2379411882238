import { useGetOrganizationQuery } from 'api/organizations';
import { useGetCurrentUserQuery } from 'api/users';
import SwissFlag from 'assets/swiss_flag.png';
import classNames from 'classnames';
import Card from 'components/base-components/Card';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader/ContentLoader';
import Icon from 'components/base-components/Icon';
import { organizationAddressToString } from 'components/organisations/organisation-address';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { dateMonthYearWithSlash } from 'utils/date-time-formatter';
import generateQRCodeContent from 'utils/generate-qr-code-content';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import InvoiceStatusTag from 'components/invoices/helpers/invoice-tag.component';
import { CREDIT_ACCOUNT_NUMBER, getInvoiceBillingInfo } from '../helpers/invoice-info';
import { creditor } from '../helpers/invoice-config';
import '../invoice.styles.scss';

const InvoiceDetailsItem = ({ label, value, className }) => (
  <div className={classNames('invoice-details__item', className)}>
    <span className="invoice-details__item--label">{label}</span>
    <span className="invoice-details__item--value">{value}</span>
  </div>
);

const InvoiceDetails = ({ invoice }) => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const [qrCodeContent, setQRCodeContent] = useState(null);
  const { invoiceId, invoiceDate, dueDate, reference } = invoice;
  const formattedInvoiceDate = dateMonthYearWithSlash(invoiceDate);
  const formattedDueDate = dateMonthYearWithSlash(dueDate);

  const isAdmin = isBioSuisseAdmin(currentUser);

  const {
    data: organization,
    isSuccess: isOrganizationFetched,
    isFetching,
  } = useGetOrganizationQuery(invoice?.organizationId, { skip: !invoice });

  const setQRCodeData = () => {
    const billingInfo = getInvoiceBillingInfo(organization, invoice);
    setQRCodeContent(generateQRCodeContent(billingInfo));
  };

  useEffect(() => {
    if (isOrganizationFetched) {
      organization && setQRCodeData();
    }
  }, [isOrganizationFetched]);

  return (
    <Card>
      <div className="invoice-details__header">
        <span className="invoice-details--title">{t('invoice.invoice_details')}</span>
        <InvoiceStatusTag invoice={invoice} />
      </div>
      <InvoiceDetailsItem label={t('invoice.id')} value={invoiceId} />
      <InvoiceDetailsItem label={t('invoice.date')} value={formattedInvoiceDate} />
      <InvoiceDetailsItem label={t('invoice.due_date')} value={formattedDueDate} />
      {isAdmin && (
        <>
          <hr className="invoice-details__horizontal-line" />
          <span className="invoice__organization--title">{t('invoice.organisation_details')}</span>
          {isFetching ? (
            <ContentLoaderPlaceholder numberOfLines={1} diameter="tiny" />
          ) : (
            <InvoiceDetailsItem
              label={t('invoice.invoice_mailing_address')}
              value={organizationAddressToString(invoice.address)}
            />
          )}
        </>
      )}
      <hr className="invoice-details__horizontal-line" />
      <div className="invoice-details__banking-information">
        {isAdmin ? (
          <>
            <span className="invoice__organization--title">{t('invoice.accounting_details')}</span>
            <InvoiceDetailsItem
              className="invoice-details__item--accounting"
              label={t('invoice.organisation_nr')}
              value={organization?.debitNumber}
            />
            <InvoiceDetailsItem
              className="invoice-details__item--accounting"
              label={t('invoice.credit_account')}
              value={CREDIT_ACCOUNT_NUMBER}
            />
          </>
        ) : (
          <div className="invoice-details__bank-details invoice-details__banking-information--header">
            <Icon
              size="medium"
              className="invoice-details__banking-information--header-icon"
              name="bank"
            />
            <span>{t('invoice.bio_suisse_banking_info')}</span>
          </div>
        )}
        <div className="invoice-details__bank-details invoice-details__bank-details--iban">
          <span className="invoice-details__bank-details--title">{t('invoice.iban')}</span>
          <span>{creditor.account}</span>
        </div>
        <div className="invoice-details__bank-details invoice-details__bank-details--margin">
          <span className="invoice-details__bank-details--block-element">{creditor.account}</span>
          {creditor.nameArray.map((name, index) => (
            <span key={index} className="invoice-details__bank-details--block-element">
              {name}
            </span>
          ))}
        </div>
        <div className="invoice-details__bank-details">
          <span className="invoice-details__bank-details--title">{t('invoice.reference')}</span>
          <span>{reference}</span>
        </div>
        {!isAdmin && qrCodeContent && (
          <>
            <hr className="invoice-details__horizontal-line" />
            <QRCode
              size={173}
              value={qrCodeContent}
              imageSettings={{
                src: SwissFlag,
                height: 22,
                width: 22,
                excavate: true,
              }}
            />
          </>
        )}
      </div>
    </Card>
  );
};

InvoiceDetailsItem.defaultProps = {
  className: '',
};

InvoiceDetailsItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
};

InvoiceDetails.propTypes = {
  invoice: PropTypes.shape().isRequired,
};

export default InvoiceDetails;
