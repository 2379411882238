import { useGetInvoiceItemsQuery } from 'api/invoices';
import { TableBody, TableData, TableRow } from 'components/base-components/Table';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { monthYearShort } from 'utils/date-time-formatter';
import { PRODUCER } from 'utils/organisation-industries';
import '../../invoice.styles.scss';

const InvoiceLineItems = ({ invoice, organization }) => {
  const isProducerInvoice = organization?.industry === PRODUCER;
  const { data: lineItems, isFetching } = useGetInvoiceItemsQuery({ invoiceId: invoice.id });
  let serialNo = 1;

  return (
    <>
      {isFetching ? (
        <TableLoaderPlaceholder numberOfRows={5} numberOfColumns={5} />
      ) : (
        <>
          {lineItems.length ? (
            <TableBody>
              {lineItems.map(lineItem => {
                const { id, name, description, quantity, unit, vatInformations } = lineItem;

                return vatInformations?.map(data => (
                  <TableRow key={id} className="invoice-table--row">
                    <TableData align="center">{serialNo++}.</TableData>
                    <TableData className="invoice__service">
                      <span>
                        {name}{' '}
                        {data.periodFrom && data.periodUntil && (
                          <>
                            (
                            {data.periodFrom !== data.periodUntil &&
                              `${monthYearShort(data.periodFrom)} - `}
                            {monthYearShort(data.periodUntil)})
                          </>
                        )}
                      </span>
                      <div>
                        <span className="invoice__service--description">
                          {t('invoice.description')}
                        </span>{' '}
                        : <span>{description}</span>
                      </div>
                      <div>
                        {`${t('invoice.vat_chf')} ${data.vatAmountInTotalMonths} (${
                          data.percentage || '0.0'
                        }%)`}
                      </div>
                    </TableData>
                    <TableData align="center">{quantity}</TableData>
                    {isProducerInvoice && (
                      <TableData align="center">{unit || t('common.not_applicable')}</TableData>
                    )}
                    <TableData align="right">{data.priceForTotalMonth}</TableData>
                    <TableData align="right">{data.amountInTotalMonth}</TableData>
                  </TableRow>
                ));
              })}
            </TableBody>
          ) : (
            <TableRow className="invoice-table--row">
              <TableData colspan={5}>
                <EmptyContentPlaceholder iconName="neutralOutline" text={t('invoice.no_invoice')} />
              </TableData>
            </TableRow>
          )}
        </>
      )}
    </>
  );
};

InvoiceLineItems.propTypes = {
  invoice: PropTypes.shape().isRequired,
  organization: PropTypes.shape().isRequired,
};

export default InvoiceLineItems;
