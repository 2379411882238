import React from 'react';
import EmphasisTag from 'components/base-components/EmphasisTag';
import { t } from 'i18next';
import { GENERATED } from 'components/invoices/helpers/invoice-tag-types';
import PropTypes from 'prop-types';

const InvoiceJobTag = ({ status }) => {
  const getStatus = () => {
    const invoiceJobStatus = {
      scheduled: {
        type: 'accent',
        text: t('invoice.tag_status.scheduled'),
      },
      scheduled_for_release: {
        type: 'accent',
        text: t('invoice.tag_status.scheduled_for_release'),
      },
      needsAction: {
        type: 'warning',
        text: t('invoice.needs_action'),
      },
      completed: {
        type: 'success',
        text: t('invoice.tag_status.completed'),
      },
      cancelled: {
        type: 'disabled',
        text: t('invoice.tag_status.cancelled'),
      },
    };

    if (status === GENERATED) return invoiceJobStatus['needsAction'];
    return invoiceJobStatus[status];
  };

  return <EmphasisTag type={getStatus(status)?.type} size="tiny" text={getStatus(status)?.text} />;
};

InvoiceJobTag.propTypes = {
  status: PropTypes.string.isRequired,
};

export default InvoiceJobTag;
